import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
  notificationClear,
  setflowDataLoading,
  setUIFormPayloadLoading
} from "../slices/notificationSlice";
import {
  setAllFormData,
  setSingleFormData,
  setformCreate,
  setUserForm,
  setIsSaved,
  setSectionId,
  setVersionJsonData,
  setAllForms,
  setFormVersions,
  setFormJsonData,
  setUiFormPayload,
  setBillingEntitiesData,
  setUploadedDocuments,
} from "../slices/flowFormSlice";
import apiClient from "../../config/apiClient";
import mixpanel from "mixpanel-browser";
import dotnetApiClient from "../../config/dotnetApiClient";
import {
  anonymousUser,
  checkDependantQuestionDefaultValue,
  logErrorFunction,
  logFunction,
  shouldThirdPartyIntegration,
  storeSanitizedData,
} from "../../helper/service";
import { getProgressData } from "./documentThunk";
import { RootState, AppDispatch } from "../store";

const userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData") || "{}")
  : null;

const handleLogout = () => {
  const isIframe = window.self !== window.top;
  
  let redirectUrl = '';
  localStorage.clear();
  sessionStorage.clear();

  if (isIframe) {
    redirectUrl = window.IFRAME_REDIRECT_URL;
    if (window.top) {
      window.top.location.href = redirectUrl;
    }
  } else {
    redirectUrl = window.CURRENT_HOST;
  }
}

export const getAllForm: any = createAsyncThunk(
  "getAllForm",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().get(`flows/`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllFormData(response?.data?.data));
        // dispatch(notificationSuccess("Get form Data "));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getPublicUserForm: any = createAsyncThunk(
  "getPublicUserForm",
  async (_request: any, { dispatch }) => {
    try {
      let visitorId = _request?.visitorId;
      let id = _request?.id;
      dispatch(setLoading(true));
      const response = await apiClient().get(
        `public/public-user-form/${id}/${visitorId}`
      );
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllFormData(response?.data?.data));
        // dispatch(notificationSuccess("Get form Data "));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getAllPublicForm: any = createAsyncThunk(
  "getAllPublicForm",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().get(`public/get-flows/`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllFormData(response?.data?.data));
        // dispatch(notificationSuccess("Get form Data "));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const publicUserFormAdd: any = createAsyncThunk(
  "publicUserFormAdd",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await apiClient().post(
        `/public/userform/add/`,
        _request
      );
      dispatch(setLoading(false));
      // dispatch(notificationSuccess("Update form Data successfully!"));

      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      } else {
        dispatch(setUserForm(response?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const createFormName: any = createAsyncThunk(
  "createFormName",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(`/UiFlowJson?billingEntityId=` + _request.billingEntityId, _request.formDetails);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(notificationSuccess("Create form name successfully!"));
        dispatch(setformCreate(response?.data));
        dispatch(setIsSaved(false));
        dispatch(getAllForms(_request.billingEntityId));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          error?.response?.data?.message || "Something went wrong"
        )
      );
    }
  }
);

export const createForm: any = createAsyncThunk(
  "createForm",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setIsSaved(false));
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(`/UiFlowJson?billingEntityId=` + _request.billingEntityId, _request.generateData);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(notificationSuccess("Create form successfully!"));
        dispatch(setformCreate(response?.data));
        dispatch(setIsSaved(true));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const updateForm: any = createAsyncThunk(
  "updateForm",
  async (_request: any, { dispatch }) => {
    try {
      let getId = _request?.formId;
      let getData = {
        node_data: _request?.formState?.node_data,
        ui_data: _request?.formState?.ui_data,
      };
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await apiClient().put(
        `flows/flowdata/update/${getId}/`,
        getData
      );
      // const response: any = "";
      dispatch(setLoading(false));
      dispatch(notificationSuccess("Update form Data successfully!"));
      if (response?.data) {
        // dispatch(setUpdateFormData(response?.data?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const addUserForm: any = createAsyncThunk(
  "addUserForm",
  async (_request: any, { dispatch, getState }) => {
    const state: any = getState();
    const { getSignleFormData } = state?.formReducer;
    let uiJson = JSON.parse(getSignleFormData?.uiJson || "[]");
    let flowJson = JSON.parse(getSignleFormData?.flowJson || "[]");
    let usapath_etag = sessionStorage.getItem("Usapath-Etag");
    let headers: any = {};

    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());

      const _req = JSON.parse(_request?.form_data || "{}");

      sessionStorage.removeItem("updatedFormValues");

      let tempLocal: any = JSON.parse(
        sessionStorage.getItem("unSavedFormValues") || "{}"
      );
      const userDataLocal = localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData") || "{}")
        : null;

      let tempArr: any = { ...tempLocal, ..._req }; // Copy existing key-value pairs from req

      const uiJsonMap = new Map(uiJson.map((item: any) => [item.id, item]));

      // Use reduce instead of flatMap for better performance
      const mergedData = flowJson?.nodes?.reduce(
        (acc: any[], flowJsonData: any) => {
          const matchingItem = uiJsonMap.get(flowJsonData.id);
          if (matchingItem) {
            acc.push({ ...matchingItem, ...flowJsonData });
          }
          return acc;
        },
        []
      );

      // if (Object.keys(tempArr).length > 0) {
      mergedData
        ?.filter((data: any) => data.data.nodeType === "question")
        .map((data: any) => {
          if (!Object.keys(tempArr).includes(data?.id)) {
            // Add new key-value pair into tempArr
            tempArr[data.id] = "";
          }
        });

      Object.entries(tempArr)?.map((record: any) => {
        if (record[1] === "") {
          mergedData
            ?.filter(
              (val: any) =>
                val?.id == record[0] && val.data.nodeType === "question"
            )
            .map((rec: any) => {
              tempArr[rec.id] = checkDependantQuestionDefaultValue(
                rec,
                tempArr
              );
            });
        }
      });

      dispatch(setUIFormPayloadLoading(true));

      if (usapath_etag !== undefined) {
        headers["Usapath-Etag"] = usapath_etag;
      }

      if (
        anonymousUser() ||
        sessionStorage.getItem("Usapath-Emulate-UserScope") === "Readonly"
      ) {
        storeSanitizedData(tempArr, null);
        dispatch(setLoading(false));
        dispatch(setUIFormPayloadLoading(false));
        return true;
      }

      const response = await dotnetApiClient().post(`/uiFormPayload`, tempArr, {
        headers,
      });

      const res_headers = response.headers;
      const etag = res_headers["usapath-etag"];

      dispatch(setLoading(false));
      dispatch(setUIFormPayloadLoading(false));

      if (!response?.data) {
        dispatch(
          notificationFail(
            response?.data?.message ||
            "We have detected an error. Please contact support for assistance."
          )
        );
      } else {
        dispatch(setLoading(false));
        await sessionStorage.setItem("Usapath-Etag", etag);

        if (shouldThirdPartyIntegration()) {
          mixpanel.track("POST UiFormPayload request completed");
        }

        storeSanitizedData({ ...response.data }, userDataLocal?.email);
      }
      return response;
      // }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
    }
  }
);

export const addPublicUserForm: any = createAsyncThunk(
  "addPublicUserForm",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await apiClient().post(
        `/public/publicUserForm/add`,
        _request
      );
      dispatch(setLoading(false));
      // dispatch(notificationSuccess("Update form Data successfully!"));

      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      } else {
        dispatch(setUserForm(response?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getUserForm: any = createAsyncThunk(
  "getUserForm",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(notificationClear());
      const response = await apiClient().post(
        `/flows/userform/getForm/`,
        _request
      );
      dispatch(setLoading(false));
      if (!response?.data) {
        dispatch(setUserForm(null));
        // dispatch(
        //   notificationFail(response?.data?.message || "Old Data Not found")
        // );
      } else {
        dispatch(setUserForm(response?.data?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setUserForm(null));
      dispatch(setLoading(false));
      // dispatch(notificationFail("Old Data Not found"));
    }
  }
);

export const getSingleForm = createAsyncThunk<
  void,
  { uiFlowJsonId: string },
  {
    dispatch: AppDispatch;
    state: RootState;
  }>
  (
    "getSingleForm",
    async ({ uiFlowJsonId }, { dispatch, getState }) => {
      const userDataLocal = localStorage.getItem("userData") ?
        JSON.parse(localStorage.getItem("userData") || "{}") :
        null;
      // logFunction(userDataLocal);
      if (!userDataLocal) {
        // dispatch(notificationFail("User data not available"));
        return;
      }
      try {
        dispatch(setflowDataLoading(true));
        const response = await dotnetApiClient().get(`UiFlowJson/${uiFlowJsonId}`);

        if (response?.data) {
          dispatch(setSingleFormData(response.data));
        } else {
          dispatch(notificationFail(response?.data?.message || "Something went wrong"));
        }
        dispatch(setflowDataLoading(false));
      } catch (error: any) {
        if (error?.response?.status === 401 || error?.response?.status === 406) {
          handleLogout()
        }
        dispatch(setflowDataLoading(false));
        dispatch(notificationFail("Something went wrong"));
      }
    }
  );

// export const getSingleForm: any = createAsyncThunk(
//   "getSingleForm",
//   async (_request: any, { dispatch }) => {
//     try {
//       dispatch(setflowDataLoading(true));
//       const response = await dotnetApiClient().get(
//         `UiFlowJson/${_request?.uiFlowJsonId}`
//       );

//       if (response?.data) {
//         dispatch(setSingleFormData(response?.data));
//       } else {
//         dispatch(
//           notificationFail(response?.data?.message || "Something went wrong")
//         );
//       }
//       dispatch(setflowDataLoading(false));
//     } catch (error) {
//       dispatch(setflowDataLoading(false));
//       dispatch(notificationFail("Something went wrong"));
//     }
//   }
// );

export const getSetSectionId: any = createAsyncThunk(
  "getSetSectionId",
  async (_request, { dispatch }) => {
    try {
      dispatch(setSectionId(_request));
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getAllForms: any = createAsyncThunk(
  "getAllForms",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/UiFlowJson?billingEntityId=` + _request);
      dispatch(setLoading(false));

      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Form data Not found")
        );
      } else {
        dispatch(setAllForms(response?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Form data Not found"));
    }
  }
);

export const getBillingEntities: any = createAsyncThunk(
  "getBillingEntities",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/billing`);

      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Could not get billing entities data")
        );
      } else {
        logFunction('response data is : ' + response.data);
        dispatch(setBillingEntitiesData(response?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Billing entities data not found"));
    }
  }
);

export const getFormVersions: any = createAsyncThunk(
  "getFormVersions",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      // dispatch(notificationClear());
      // const response = await apiClient().get(`/flows/forms`);
      const response = await dotnetApiClient().get(
        `/UiFlowJson/${_request.flowId}`
      );
      dispatch(setLoading(false));
      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Form data Not found")
        );
      } else {
        dispatch(setFormVersions(response?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Form data Not found"));
    }
  }
);

export const getFormJsonData: any = createAsyncThunk(
  "getFormJsonData",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      // dispatch(notificationClear());
      // const response = await apiClient().get(`/flows/forms`);
      const response = await dotnetApiClient().get(
        `/UiFlowJson/${_request.id}`
      );
      dispatch(setLoading(false));
      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Form data Not found")
        );
      } else {
        dispatch(setFormJsonData(response?.data || {}));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Form data Not found"));
    }
  }
);

export const getVersionJsonData: any = createAsyncThunk(
  "getVersionJsonData",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      // dispatch(notificationClear());
      const response = await apiClient().get(`/flows/flowdata/${_request}`);
      dispatch(setLoading(false));
      if (!response?.data) {
        dispatch(
          notificationFail(
            response?.data?.message || "Json Not found for this version"
          )
        );
      } else {
        dispatch(setVersionJsonData(response?.data?.data));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Json Not found for this version"));
    }
  }
);

export const replenish: any = createAsyncThunk(
  "replenish",
  async (_, { dispatch }) => {
    const userDataLocal = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData") || "{}")
      : null;
    try {
      if (anonymousUser()) {
        // Below line is for incognito user issue while loggin and signout
        sessionStorage.clear();
        return true;
      }
      dispatch(setLoading(true));
      dispatch(setUiFormPayload(null));
      const response = await dotnetApiClient().get(`/UiFormPayload`);

      // const headers = response.headers;
      // const etag = headers["usapath-etag"];
      // sessionStorage.setItem("Usapath-Etag", etag);

      const res_headers = response.headers;
      const etag = res_headers["usapath-etag"];

      sessionStorage.setItem("Usapath-Etag", etag);

      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Data not  found")
        );
      } else {
        if (etag !== null) {
          if (response?.data && !Object.keys(response?.data).length) {
            dispatch(addUserForm()).then((res: any) => {
              if (res?.payload?.data) {
                dispatch(getProgressData());
                sessionStorage.setItem(
                  "unSavedFormValues",
                  JSON.stringify(res?.payload?.data)
                );
                dispatch(setUiFormPayload(res?.payload?.data || {}));
              }
            });
          } else {
            dispatch(getProgressData());
            sessionStorage.setItem(
              "unSavedFormValues",
              JSON.stringify(response?.data)
            );
            dispatch(setUiFormPayload(response?.data || {}));
          }

          storeSanitizedData({ ...response.data }, userDataLocal?.email);
          (window as any).sanitizedData["user_id"] = userDataLocal?.email;
          dispatch(setLoading(false));
          return true;
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Data not  found"));
    }
  }
);

export const fetchDocuments: any = createAsyncThunk(
  "fetchDocuments",
  async (userId: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/UserDocument/user/${userId}/list`);
      dispatch(setLoading(false));
      if (!response?.data) {
        dispatch(
          notificationFail(
            response?.data?.message || "Json Not found for this version"
          )
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setUploadedDocuments(response.data));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      logErrorFunction("Error fetching documents:" + error);
      dispatch(setLoading(false));
    }
  }
);
export const fetchDocumentsOfClient: any = createAsyncThunk(
  "fetchDocumentsOfClient",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`/UserDocument/list`);
      dispatch(setLoading(false));
      if (!response?.data) {
        dispatch(
          notificationFail(
            response?.data?.message || "Json Not found for this version"
          )
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setUploadedDocuments(response.data));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout()
      }
      logErrorFunction("Error fetching documents:" + error);
      dispatch(setLoading(false));
    }
  }
);
